<template>
    <div>
        <div class="header pb-8 pt-2 pt-lg-6 d-flex align-items-center">
            <!-- Mask -->
            
            <!-- Header container -->
            <div class="container-fluid d-flex align-items-center">

                <div class="col-lg-7 p-0 col-md-10">
                    <h2 class="display-2 mb-0 text-white">Prístup odmietnutý</h2>
                    <h4 class=" display-5 text-white">Na prístup k tejto stránke nemáte oprávnenie</h4>

                </div>
            </div>
        </div>
    </div>
</template>
<script>

    export default {
        name: 'denied',

        data() {
            return {
                colorsto: JSON.parse(localStorage.colors),
            }
        },
        methods: {
        },
        mounted() {
        }
    };
</script>
